import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
      className={
        "landingpage w-screen flex items-center justify-center h-screen"
      }
    > 
      <div className={'text-center'}>
        <h1 className={'text-4xl mb-8'}>{`{`} Nothing Found {`}`}</h1>
        
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
